import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "Order",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '订单状态',
        value: '',
        type: 'select',
        list: [{
          id: 1,
          name: '已处理'
        }, {
          id: 0,
          name: '未处理'
        }, {
          id: -1,
          name: '已禁用'
        }, {
          id: -9,
          name: '已删除'
        }]
      }, {
        placeholder: '',
        value: '',
        type: 'data',
        list: []
      }, {
        placeholder: '订单号',
        value: '',
        type: 'text',
        list: []
      }],
      activeName: 'first',
      postListData: {
        orgId: '',
        //机构Id
        state: '',
        crmCode: '',
        start: '',
        end: '',
        pageSize: '',
        pageIndex: ''
      },
      // 用户信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // dialog弹框-删除弹框
      delId: '',
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },
  mounted: function mounted() {
    if (parseInt(this.$route.query.id) === parseInt(this.Ls.getToken("nof_orgId"))) {
      // 路径传过来的机构id和缓存中一致时候，oId两者都行
      this.postListData.orgId = this.$route.query.id || this.Ls.getToken("nof_orgId");
    } else {
      // id不一致的时候，以路径值为准
      if (this.$route.query.id) {
        this.postListData.orgId = this.$route.query.id;
      } else {
        this.postListData.orgId = this.Ls.getToken("nof_orgId");
      }
    }
    //获取数据
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.service.axiosUtils({
        requestName: 'getOrgOrderList',
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.tableData = res.data.data;
            _this.total = res.data.total;
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this2 = this;
      var rowState = '';
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: 'changeFunctionState',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this3 = this;
      if (type === 'order') {
        // 处理订单
        this.editState(val);
      } else if (type === 'send') {
        // 重发短信
        this.service.axiosUtils({
          requestName: 'sendSMSOrgOrder',
          data: {
            username: val.username,
            telephone: val.telephone,
            orderId: val.orderId
          },
          sucFn: function sucFn(res) {
            if (res.status === 1) {
              _this3.initData();
            } else {
              _this3.$toast.error(res.message);
            }
          }
        });
      } else if (type === 'del') {
        // 删除订单
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else if (type === 'back') {
        // 嵌套表格-收回授权
      }
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this4 = this;
      //删除当前的功能
      this.service.axiosUtils({
        requestName: 'deleteOrgOrder',
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.$toast.success(res.message);
            _this4.delId = '';
            _this4.centerDialogVisible = false;
            _this4.initData();
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        this.postListData.crmCode = filterData[2].value;
        this.postListData.start = filterData[1].value[0];
        this.postListData.end = filterData[1].value[1];
        this.postListData.state = filterData[0].value;
      } else {
        this.postListData.crmCode = '';
        this.postListData.start = '';
        this.postListData.end = '';
        this.postListData.state = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.crmCode = '';
      this.postListData.start = '';
      this.postListData.end = '';
      this.postListData.state = '';
      this.currentPage = 1;
      this.initData();
    }
  }
};